// import moment from 'moment'
import moment from '@/libs/moment'

const timezone = localStorage.getItem('timezone')

export const filters = {
  filters: {
    shortDate(value) {
      return value ? moment(value).tz(timezone).format('YYYY-MM-DD') : ''
    },
    longDate(value) {
      return value ? moment(value).tz(timezone).format('YYYY-MM-DD HH:mm') : ''
    },
    longDateTime(value) {
      return value ? moment(value).tz(timezone).format('YYYY-MM-DD HH:mm:ss') : ''
    },
    shortDateLocal(value) {
      return value ? moment(value).tz(timezone).format('MM/DD/YYYY') : ''
    },
    longDateLocal(value) {
      return value ? moment(value).tz(timezone).format('MM/DD/YYYY HH:mm:SS') : ''
    },
    longDateTimeLocal(value) {
      return value ? moment(value).tz(timezone).format('MM/DD/YYYY h:mm a') : ''
    },
    dateToLocal(value) {
      return value ? moment(value).tz(timezone).format('MM/DD/YYYY h:mm a') : ''
    },
    dateToTime(value) {
      return value ? moment(value).tz(timezone).format('h:mm a') : ''
    },
    dateToString(value) {
      return value ? moment(value).tz(timezone).toString() : ''
    },
    dateToDetailString(value) {
      return value ? moment(value).tz(timezone).format('dddd, ll') : ''
    },
    dateToTZ(value) {
      return value ? `GMT${moment(value).tz(timezone).format('ZZ')}` : ''
    },
    shortId(id) {
      return id ? id.slice(-4) : ''
    },
    fixAmount(val) {
      return val >= 0 ? `$${val.toFixed(2)}` : `-$${Math.abs(val.toFixed(2))}`
    },
    roundUpAmount(val) {
      return val.toFixed(2)
    },
    fixNumber(val) {
      return val ? Number(val).toFixed(0) : 0
    },
  },
  methods: {
    formatDate(value) {
      return value ? moment(value).tz(timezone).format('YYYY-MM-DD HH:mm') : ''
    },
  },
}

export const _ = null
