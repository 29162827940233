import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLettersSetting() {
  // Use toast
  const toast = useToast()

  const fetchUniqueFields = () => axios
    .get('/auth/unique-fields')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching attribute handlers',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchPlaceholders = () => axios
    .get('/auth/placeholders')
    .then(response => response.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching placeholders',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchFamilyAttributes = () => axios
    .get('/auth/unique-family-attributes')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching family attributes',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchFormFields = () => axios
    .get('/auth/form-fields')
    .then(response => response.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching form fields',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchWallets = () => axios
    .get('/auth/wallets')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching wallets',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchGroupTypesForSelect = queryParams => axios
    .get('/auth/group-types-for-select', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching group types',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchUniqueFields,
    fetchPlaceholders,
    fetchFamilyAttributes,
    fetchFormFields,
    fetchWallets,
    fetchGroupTypesForSelect,
  }
}
