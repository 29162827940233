import axios from '@axios'

export const fetchApplicationTasksList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/application-tasks', { params })
  return res
}

export const fetchApplicationTask = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/application-tasks/${id}`)
  return res
}

export const updateApplicationTask = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/application-tasks/update-result', params)
  return res
}

export const deleteApplicationTask = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/application-tasks/${id}`)
  return res
}
